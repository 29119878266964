.dentists-container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 200px;

}
.dentists-wrapper{
    max-width: 1300px;
    width: 100%;
    padding: 0 20px;

}
.dentists-header{
max-width: 1100px;
width: 100%;
margin-bottom: 150px;
padding: 0 20px;
}
.dentists-header h1{
    font-style: normal;
    font-weight: 500;
    font-size: 50px;
    line-height: 61px;
    margin-bottom: 50px;
    margin-top: 50px;
    background: linear-gradient(270.1deg, #949494 -4.69%, #B0B0B0 72.26%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
background-clip: text;
text-fill-color: transparent;
}
.dentists-header p{
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 30px;
    color: rgb(16, 58, 30);
    
}

.dentists-list-wrapper{
    max-width: 1300px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 100px;
    margin-top: -150px;
}
@media only screen and (max-width: 568px) {
    .dentists-wrapper{
        padding: 0;
    }
	.dentists-list-wrapper{
        flex-direction: column;
        align-items: center;
    }
    .dentists-list-wrapper{
        padding: 0px ;
    }.dentists-header h1{
        font-size: 34px;
    }.dentists-header p{
        font-size: 14px;
    }
   
 
  }
@media only screen and (max-width: 810px) {
    .dentists-list-wrapper{
      
        justify-content:center;
      
        
    }
   
 
  }