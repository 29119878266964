.gallerypage-container{
    width: 1400px;
    margin: 20px auto;
    columns: 4;
    column-gap: 40px;
}
.gallerypage-box{
    width: 100%;
    margin-bottom: 10px;
    break-inside: avoid;
}
.gallerypage-box img{
    max-width: 100%;
    border-radius: 15px;
}

@media(max-width:1200px){
    .gallerypage-container{
        width: calc(100% - 40px);
        columns:3
    }
}
@media(max-width:760px){
    .gallerypage-container{
      
        columns:2
    }
}

