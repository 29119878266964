.recentposts-cont{
    background-color: white;
    padding: 5px ;
    border-radius: 5px;
    height: 300px;
    width: 100px;
    display: flex;
    
   
    flex-direction: column;
    gap: 20px;
}
.recentposts-post{
    width: 190%;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    border-radius: 50px;
}
.rplink{
    
    color: rgb(37, 37, 37);
}
.recentposts-post:hover{
    background-color: aliceblue;
}
.recentposts-post img{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.recentposts-post span{
   font-size: 12px;
}
@media only screen and (max-width: 580px) {
    .recentposts-cont{
        background-color: white;
        margin-top: 10px;
        padding: 5px ;
        border-radius: 5px;
       
        width: 80%;
        align-self: left;
        display: flex;
        
       
       
        gap: 20px;
    }
    
    
  }
