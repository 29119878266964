
.bloglayout{
    display: flex;
    width: 100%;
    min-height: 100vh;
    padding: 20px 20px;
    gap: 20px;
    background-color: rgb(255, 255, 255);
}
.blogposts{
    display: flex;
    flex:6;
}
.recent-cont{
    flex:1;
    background-color: white;
    height: 300px;
}
@media only screen and (max-width: 580px) {
    .bloglayout{
        flex-direction: column;
        align-items: center;
        gap: 30px;
        padding: 10px 5px;
    }
    
    
  }