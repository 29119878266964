.gallery-wrapper{
  --space: 2rem;

  display: grid;
  align-content: center;
  overflow: hidden;
  gap: var(--space);
  width: 100%;
  font-family: "Corben", system-ui, sans-serif;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 30px 10px;
  position: relative;
}
.marquee {
  --duration: 60s;
  --gap: var(--space);

  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  transform: skewY(-3deg);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: scroll var(--duration) linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .marquee__group {
    animation-play-state: paused;
  }
}

.marquee__group img {
  max-width: clamp(10rem, 1rem + 28vmin, 20rem);
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 1rem;
}

.marquee__group p {
  color: black;
}

.marquee--borders {
  border-block: 2px solid #2db2ff;
  padding-block: 0.75rem
}

.marquee--reverse .marquee__group {
  animation-direction: reverse;
  animation-delay: calc(var(--duration) / -2);
}
.gallery-header{
  text-align: center;
}
.gallery-header p {
 font-size: 14px;
 font-weight: 500;
 color: #2db2ff;

}
.gallery-header h2 {
  color: black;
 font-size: 48px;
 font-weight: 500;
 padding: 0 20px;
 
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-100% - var(--gap)));
  }
}
