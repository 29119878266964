.pros-container{
    display: flex;
    justify-content: space-around;
    padding: 100px 88px;
    /* margin-top: 200px; */
    gap: 20px;
   
}
.pros-right{
    margin-top: -50px;
}
.pros-left img{
    width: 600px;
    
}
.pros-left {
    margin-top: 50px;
    
}
  
.pros-right h2{
    color: rgb(16, 58, 30);

    max-width: 401px;
    font-size: 20px;
    width: 100%;
    margin-bottom: 70px;
 
}
.pros-right h2 span{
    font-size: 40px;
}
.pros-right p{
    max-width: 600px;
    width: 100%;
    color: rgb(16, 58, 30);

font-size: 18px;

}
@media only screen and (max-width: 768px) {

.pros-left{
    margin: 0;
    width:100%;

}
.pros-left img{
    width:100%;
}

 
  }
@media only screen and (max-width: 480px) {
   
    .pros-container h2 span{
        font-size: 30px;
    }
 
    .pros-right p{
        font-size: 15px;
    }
 
  }
  @media only screen and (max-width: 1030px) {
    .pros-container{
        flex-direction: column-reverse;
    }
  }
@media only screen and (max-width: 1030px) {
    .pros-container{
        flex-direction: column-reverse;
        align-items: center;
        padding-left:20px ;
        padding-right:20px ;
        margin-top: 20px
    }
    .pros-right{
        margin: 0;
    
    }
   
    .pros-container h2 span{
        font-size: 30px;
    }
 
    .pros-right p{
        font-size: 15px;
    }
 
  }