.working-hours-container{
    width:100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 50px 0;
}
.working-hours-wrapper{
margin-top: 50px;
padding: 10px;
}
.day{
   
    display: flex;
    align-items: center;
   max-width: 600px;
   width: 100%;
    gap: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    background: rgba(217, 217, 217, 0.01);
border: 1px solid rgba(120, 120, 120, 0.54);
border-radius: 12px;
backdrop-filter: blur(6px);
color: rgba(107, 107, 107, 0.71);

}
.day:last-child{
    justify-content: start;
    gap: 20px;
}
.day:nth-child(6){
    justify-content: start;
  

}
.day-span{
    font-weight: 600;
    width: 100px;
}
.hours-span{
    color: rgba(107, 107, 107, 0.71);
    font-weight: 400;
    margin-left: 20px;
    
    margin-right: 40px;
}
.break{
    color: tomato;
    margin-right: 20px;
}
.tooth3{
    position: absolute;
    left: -100px;
    top: -300px;
    z-index: -1;
}
@media only screen and (max-width: 768px) {
    .working-hours-container{
        height: 100vh;
    }
	.tooth3{
		width: 700px;
        height: 700px;
        top: 0;
        
	}
	.tooth3 img{
		width: 100%;
        height: 100%;
        
	}
	
 
  }
@media only screen and (max-width: 620px) {
    .day-span{
font-size: 12px;
}
	.hours-span{
		font-size: 12px;
        margin-left: 10px;
    
    margin-right: 20px;
	}
	.break{		font-size: 12px;
        margin-right: 10px;

	}
	
 
  }
@media only screen and (max-width: 620px) {
    .day-span{
font-size: 12px;
}
	.hours-span{
		font-size: 12px;
        margin-left: 10px;
    
    margin-right: 20px;
	}
	.break{		font-size: 12px;
        margin-right: 10px;

	}
    .day span{
        font-size: 12px;
    }
   .hours-span{
    margin-right: 5px;
   }
  
	
 
  }

  @media only screen and (max-width: 480px) {
    .day-span{
        margin-left: 14px;
    }
    .hours-span{
        margin-left: 5px;
        margin-right: 0;
       }
  }