:root {
    --primary: #6cebeb;
    --secondary: #FE4880;
    --dark: #212121;
    --light: #F3F3F3;
  }
.service-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px ;
    padding: 50px;
    overflow-x: hidden;
    /* background-color: rgb(234, 240, 245); */
    /* padding-bottom: 0px; */
    
}

.service-container h1 {
    background: linear-gradient(to right, #6cebeb, #FFC300);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 50px;
    width: 100%;
    text-align: center;

}
.card {
    margin: 100px auto 0;
    width: 400px;
    height: 550px;
    /* perspective: 1000px; */
  }
  
  .card__inner {
    width: 100%;
    height: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
    cursor: pointer;
    position: relative;
    transform: rotateY(180deg);
  }
  
  
  .card__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    overflow: hidden;
    border-radius: none;
    box-shadow: 0px 3px 18px 3px rgba(0, 0, 0, 0.2);
  }
  
  .card__face--front {
    background-image: linear-gradient(to bottom right, var(--primary), var(--secondary));
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  
  .card__face--front h2 {
    color: #FFF;
    font-size: 32px;
  }
  
  .card__face--back {
    background-color: var(--light);
    transform: rotateY(180deg);
  }
  
  .card__content {
    width: 100%;
    height: 100%;
   
  }
  
  .card__header {
    position: relative;
    padding: 30px 30px 40px;
  }
  
  .card__header:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom left, var(--primary) 10%, var(--secondary) 115%);
    z-index: -1;
    border-radius: 0px 0px 50% 0px;
  }
  
  .pp {
    display: block;
    width: 128px;
    height: 128px;
    margin: 0 auto 30px;
    border-radius: 50%;
    background-color: #FFF;
    border: 5px solid #FFF;
    object-fit: cover;
  }
  
  .card__header h2 {
    color: #FFF;
    font-size: 24px;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
  }
  
  .card__body {
    padding: 20px;
  }
  
  .card__body h3 {
    color: var(--dark);
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  
  .card__body p {
    color: var(--dark);
    font-size: 15px;
    line-height: 1.4;
  }
  .service-javshani{
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: #6cebeb;
    padding: 8px 12px;
    border-radius: 15px;
    color: #ffffff;
  }
  .service-img-front{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    border-radius: 15px;
  }
  .card__face--front h2{
    position: absolute;
    bottom: 150px;
  }
 
 @media only screen and (max-width: 480px) {
  
    .service-container h1{
        font-size: 18px;
    }
    .service-container{
      padding: 20px 0px;
    }
      
  .card__body p {
    color: var(--dark);
    font-size: 13px;
    line-height: 1.4;
  }
  }
