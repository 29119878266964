.dentist-card{
    position: relative;
    width:350px;
    height: 275px;
    background: white;
    border-radius: 20px;
    transition: 0.5s;
    box-shadow: 0 35px 80px rgba(0,0,0,0.15);
}
.dentist-card:hover{
scale: 1.1;}
.dentist-card-imgbox{
    position: absolute;
    left: 50%;
    top: -50px;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    transition: 0.5s;
    box-shadow: 0 15px 50px rgba(0,0,0,0.35);

}
.dentist-card:hover .dentist-card-imgbox{
    width: 200px;
    height: 200px;
}
.dentist-card-imgbox img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.dentist-card-content{
    position: absolute;
    bottom: -40px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transition: 0.5s;
}
.dentist-card:hover .dentist-card-content{
    bottom: -60px;
}
.dentist-card-details{
    padding: 10px;
    text-align: center;
    width: 100%;
    transition: 0.5s;
}
.dentist-card-details h2{
    font-size: 1.25em;
    font-weight: 600;
    color: #555;
    line-height: 1.2em;
}
.dentist-card-details h2 span{
    font-size: 0.75em;
    font-weight: 500;
    opacity: 0.5;
}
.dentist-card-action-btn{
    display: flex;
    justify-content: center;
    gap: 20px;

}
.dentist-card-action-btn p {
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 500;
    background-color: rgb(96, 96, 235);
    color: white;
    cursor: pointer;
}
@media only screen and (max-width: 320px) {
    .dentist-card{
       width: 300px;
    
  }
}