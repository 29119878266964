.navigation-container{
	z-index: 50;
    display: flex;
    align-items: center;
    padding: 20px 10px ;
    justify-content: space-around;
background-color: #ffffff;  
box-shadow: -1px 4px 8px -3px rgba(0, 0, 0, 0.596);
/* -webkit-box-shadow: -1px 8px 5px -3px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 8px 5px -3px rgba(0,0,0,0.75); */

  

}
.nav-links{
    display: flex;
    align-items: center;
    gap: 30px;
    color:#8D8D8D ;
    font-size: 15px;
}
.link{
    cursor: pointer;
    width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.05);
	box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 30px;
	padding: 1px;
	letter-spacing: 1px;
	text-decoration: none;
	overflow: hidden;
	color: #fff;
	font-weight: 400px;
	z-index: 1;
	transition: 0.5s;
	backdrop-filter: blur(15px);
}
.link:hover{
    letter-spacing: 3px;
}
.link::before{
    content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
	transform: skewX(45deg) translate(0);
	transition: 0.5s;
	filter: blur(0px);
}
.nav-btn:hover link::before{
    transform: skewX(45deg) translate(200px);
}


.logo{
    display: flex;
    align-items: center;
	height: 50px;
    gap: 7px;
}
.logo img{
	width: 80px;
}
.logo span {
    background:linear-gradient(90deg, #96E1E1 8.2%, #009393 137.76%);
    -webkit-background-clip: text;
    font-size: 18px;
    font-weight: 500;
    -webkit-text-fill-color: transparent;
}
.nav-contact{
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    font-weight: bold;
    color: rgb(31, 3, 3);
    width: 185px;
    height: 50px;
    cursor: pointer;
}
.nav-btn{
    position: relative;
	top: 0;
	left: 0;
	width: 165px;
	height: 50px;
	margin: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.nav-btn::before{
    content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	bottom: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
    background: #2db2ff;
	box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
		0 0 60px #2db2ff;
}
.nav-btn:hover::before{
    bottom: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}
.nav-btn::after{
    content: "";
	position: absolute;
	left: 50%;
	transform: translatex(-50%);
	top: -5px;
	width: 30px;
	height: 10px;
	background: #f00;
	border-radius: 10px;
	transition: 0.5s;
	transition-delay: 0.5;
    background: #2db2ff;
	box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
		0 0 60px #2db2ff;
}
.nav-btn:hover::after{
    top: 0;
	height: 50%;
	width: 80%;
	border-radius: 30px;
}
.nav-contact .link{
background-color:rgb(62, 189, 189);}
.link{
	color: black;
}
.ham{
	display: none;
}

@media only screen and (max-width: 1300px) {
	.nav-btn{
width: 160px;
	}
}
@media only screen and (max-width: 1024px) {
	.nav-links{
		flex-direction: column;
		position: absolute;
		right: 0;
		top: -200%;
		width: 100%;
		background:  rgb(33, 35, 35) ;
		transition: 900ms ease;
		display: flex;
		z-index: 500;
		align-items: center;
		justify-content: center;
		padding: 50px 0px;
		box-sizing: border-box;
		height:calc(100vh - 50px) 
	
		
	}
	.nav-btn{
		color: white;
	}
	
	.navigation-container{
		justify-content: space-around;
		
		
	}
	.ham{
		display: flex;
		flex-direction: column;
		color: white;
		gap: 8px;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		z-index: 1000;
	}
	.ham-span{
		height: 3px;
		width: 40px;
		background-color: #2db2ff;;
		z-index: 100;
	}
 
  }