.hero-container{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 100px ;
    padding-right: 0;
    position: relative;
    gap: 200px;
    overflow: hidden;
    height: calc(100vh - 80px);
    



}

.hero-header{
    display: flex;
    flex-direction: column;
    /* margin-top: -250px; */
    gap: 0;
    z-index: 50;
}
.hero-h1{
    font-weight: bold;
    font-size: 80px;
    background: linear-gradient(to right, #6cebeb, #669e9e);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}
.hero-span{
    max-width: 500px;
    width: 100%;
    font-weight: 400;
font-size: 18px;
margin-top: -20px;
margin-left: 5px;
color: rgb(16, 58, 30);

margin-bottom: 50px;

}
.hero-a{
   border: 1px solid white;
border-radius: 36px;
width: 270px;
height: 62px;
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
color: #2d4444;
letter-spacing: 2px;
background-color: white;
transition: 0.4s ease;
}
.hero-a:hover{
    background-color: rgb(0, 0, 0);
    color: white;
}

.hero-img{
    height: 600px;
}
.hero-tooth{
    position: absolute;
    z-index: 1;
    left: -125px;
    top: -80px;
    height: 800px;
    width: 900px;
}
.arrowDown{
    position: absolute;
    bottom: 100px;
    left: 45%;
    animation: move 2s linear infinite;
}
@keyframes move {
    0%{
        bottom: 100px;
    }
    50%{
        bottom: 50px;
    }
    100%{
        bottom: 100px;
    }
}
.booking{
    width: 100px;
    height: 60px;
    border: 1px solid rgb(107, 161, 231);
    padding: 8px 12px;
    color:  rgb(107, 161, 231);
    font-weight: bold;
    border-radius: 3px;
}


@media only screen and (max-width: 768px) {
    .hero-right{
        width: 50px;
    }
   
    .hero-tooth {
        width: 700px;
        height: 700px;
        top: 0;
        
    }
    .hero-container{
        padding: 20px;
        gap: 0px;
    }
    .main-services{
        width: 100%;
    }
    .arrowDown{
        display: none;
    }
  }
@media only screen and (max-width: 664px) {
    .hero-tooth {
        width: 500px;
        height: 500px;
        left: 0%;
        top: -20%;
    }
    
  
.hero-right{
    /* position: absolute;
    right: 60px;
    height: 300px; */
    display: none;
}

   .hero-header{
    width: 100%;
    gap: 25px;
    justify-self: start;
    text-align: center;
    align-items: center;
   }
   .hero-h1{
    font-size: 64px;
   }
   .hero-span{
    font-size: 16px;
    text-align: center;
   }
 
    .hero-container{
        overflow-x: hidden;
        padding-bottom: 30px;
        height: 90vh;
        
        padding: 20px;
        gap: 0px;
        align-items: center;
        justify-content: center;
    }
    .hero-header{
        margin-top: 0;
    }
  }
@media only screen and (max-width: 480px) {
    .hero-container{
        height: 50vh;
        overflow: hidden;
    }
    .hero-tooth {
        width: 500px;
        height: 500px;
        left: 0px;
        top: -20px;
    }
   
  


   .hero-header{
    width: 100%;
    gap: 25px;
    justify-self: start;
    text-align: center;
    align-items: center;
   }
   .hero-h1{
    font-size: 64px;
   }
   .hero-span{
    font-size: 16px;
    text-align: center;
   }
 
    .hero-container{
        overflow-x: hidden;
        padding-bottom: 30px;
        
        padding: 20px;
        padding-bottom: 0;
        gap: 0px;
        align-items: center;
        justify-content: center;
    }
    .hero-header{
        margin-top: 0;
    }
  }