.works-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 50px;
    position: relative;
        /* background-color: rgb(234, 240, 245); */


}
.works-wrapper{
    display: flex;
    
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 80px;
    z-index: 1;
}
.works-card{
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    width: 350px;
    padding: 25px ;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, .25);
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.13);
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
    color: rgb(16, 58, 30);

  

}
.workby{
    font-size: 13px;
    font-weight: bold;


}
.workname{
    font-weight: lighter;
    font-size: 15px;
}
.works-card img{
    width: 100%;
    height: 350px;
    border-radius: 20px;
  
}


.work-tooth-1{
    position: absolute;
    top: 0px;
    left: -50px;
    z-index: -2;
    width: 800px;
    animation: movetooth2 10s linear infinite ;

}
.work-tooth-1 img,
.work-tooth-2 img,
.work-tooth-3 img{
    width: 100%;
}
.work-tooth-2{
    position: absolute;
    top: 0px;
    right: 0;
    z-index: -1;    
    width: 800px;
    animation: movetooth2 10s linear infinite ;

}
.work-tooth-3{
    position: absolute;
    bottom: -20%;
    right: 22%;
    z-index: -1;    
    width: 800px;
    

}
@keyframes movetooth2{
    0%{
     top: 0;
    }
    50%{
        top: 100px;

    }
    100%{
        top: 0;

    }

}
@keyframes movetooth{
    0%{
        bottom: -20%;

    }
    50%{
        bottom: -10%;

    }
    100%{
        bottom: -20%;

    }

}

.works-card{
    
    padding: 0;
}
.work-info{
    padding: 10px;
}
@media only screen and (max-width: 568px) {
.works-wrapper{
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    flex-wrap: nowrap;
    gap: 20px;
}
.work-tooth-2{
    top: 40%;
}
.work-tooth-1{
    width: 400px;
    height: 400px;
    left: 0;
}
.work-tooth-1 img{
    width: 100%;
    height: 100%;
}
   
 
  }
@media only screen and (max-width: 480px) {
.works-wrapper{
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    flex-wrap: nowrap;
    gap: 40px;
    width: 100%;
   
}
.works-container{
    padding: 50px 20px;
    

}

.works-card{
    width: 100%;
    padding: 0;
}
.work-info{
    padding: 10px;
}
   
 
  }
