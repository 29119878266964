.postcard{
    width: 325px;
    height: 375px;
  
   border-radius: 15px;
   border-bottom: 1px solid #2db2ff;
   box-shadow: 1px 1px 7px 7px rgb(255, 255, 255);
}
.postcard-img-cont {
    width: 100%;
    height: 50%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.postcard-img-cont img {
    width: 100%;
    height: 100%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.post-desc{
    padding: 10px 15px;
    height: 175px;
    
    font-size: 14px;
    
}
.post-title{
    font-size: 17px;
    text-align: center;
    font-weight: bold;
    color: rgb(41, 43, 43);
    margin-bottom: 5px;
}
.post-date{
    font-size: 12;

    color: grey;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
}
.post-link{
    margin-left: 110px;
    margin-top: 7px;
    width: 100%;
    padding: 10px 18px;
    background-color: #2db2ff;
    border-radius: 25px;
    text-align: center;
    text-decoration: none;
    color: white;
}

