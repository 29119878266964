.modal-wrapper{
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
   
}
.modal-wrapper img{
    width: 80%;
    border-radius: 20%;
}
.modal-wrapper p{
  font-size: 14px;
  margin-top: 10px;
}


@media only screen and (max-width: 480px) {
  .modal-wrapper{
    width: 100%
   
}
  
}
@media only screen and (max-width: 320px) {
  .modal-wrapper{
    width: 300px
   
}
  
}