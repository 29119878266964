.hero-stats-container{
    width: 100%;
    /* height: 300px; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-stats-wrapper{
  display: flex;
  gap: 50px;
}
.hero-stats{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #4E4E4E;
        background-color: #F6F6F6;
        padding: 50px 100px;
        border-radius: 25px;



   
}
.hero-stats p{
    width: 240px;
    font-size: 16px;
}
.hero-stats span{
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {
    .hero-stats-wrapper{
        flex-direction: column;
        gap: 50px;
    }
 
  }
@media only screen and (max-width: 1400px) {
    .hero-stats-wrapper{
        gap: 50px;
    }
    .hero-stats{
        width: 250px;
    }
 
  }
@media only screen and (max-width: 768px) {
    .hero-stats-wrapper{
        flex-direction: column;
        gap: 50px;
    }
    .hero-stats{
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: #4E4E4E;
            background-color: #F6F6F6;
            padding: 25px 50px;
            border-radius: 25px;
    
    
    
       
    }
  }