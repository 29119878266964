.postpage-cont{
  
    padding: 20px;
   
}
.cp-start-text{
    margin-top: 20px;
}
.cp-reason{
    font-weight: 500;
    margin-top: 30px;
    color: rgb(170, 169, 169)(128, 128, 128, 0.616);
}
.reason-box{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.postpage-cont p{
    font-size: 15px;
}
